<template>
  <div class="mtop-100 font-sfns">
    <b-container>
      <b-row>
        <b-col>
          <label>Year</label>
          <datepicker v-model="date" format="yyyy" minimum-view="year" placeholder="Select year"></datepicker>
        </b-col>
        <b-col>
          <label>Classifications</label>
          <b-form-select v-model="sub_classification" :options="classifications"></b-form-select>
        </b-col>
      </b-row>
     </b-container>
    <div class="ml-4 mbottom-100" style="width: 90%;">
      <vue-apex-charts width="100%" type="area" :options="monthlyOptions" :series="monthlySeries"></vue-apex-charts>
    </div>
  </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import statsOptions from '@/data/statsOptions.js'
import Datepicker from 'vuejs-datepicker'

export default {
  title: "Monthly Transactions",
  data() {
    return {
      monthlyOptions: statsOptions.monthlyChartOptions,
      loaded: false,
      date: new Date(2009, 1, 0),
            sub_classification: 1,
      months: [
        {
          value: 1,
          text: "January"
        },
        {
          value: 2,
          text: "February"
        },
        {
          value: 3,
          text: "March"
        },
        {
          value: 4,
          text: "April"
        },
        {
          value: 5,
          text: "May"
        },
        {
          value: 6,
          text: "June"
        },
        {
          value: 7,
          text: "July"
        },
        {
          value: 8,
          text: "August"
        },
        {
          value: 9,
          text: "September"
        },
        {
          value: 10,
          text: "October"
        },
        {
          value: 11,
          text: "November"
        },
        {
          value: 12,
          text: "December"
        }
      ]
    }
  },
  components: {
    VueApexCharts,
    Datepicker
  },
  computed: {
    monthlySeries() {
      return this.$store.getters['transaction/monthSeries']
    },
    classifications() {
      return this.$store.getters["transaction/listSubClassifications"]
    }
  },
  watch: {
    'date'() {
      if (this.date != "") {
        this.fetchMonthlyTransactionReport()
      }
    },
    sub_classification() {
      if (this.sub_classification != "") {
        this.fetchMonthlyTransactionReport()
      }
    }
  },
  methods: {
    fetchMonthlyTransactionReport() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("transaction/fetchMonthlyTransactionReport", {meta: {year: this.date.getFullYear(), sub_classification: this.sub_classification}})
      .then(() => {
        this.loaded = true
        loader.hide()
      })
    }
  },
  created() {
    this.$store.dispatch("transaction/fetchClassifications", {meta: {page: 1, paginate: 200}})
    this.fetchMonthlyTransactionReport()
  }

}
</script>

<style lang="scss">
</style>
